import { nanoid } from "nanoid"
import { createPersistentStore } from "src/utilities/localStorage/create"

type State = {
  id: string
}

const defaultState: State = {
  id: nanoid(),
}

const store = createPersistentStore(() => defaultState, {
  name: "id",
  persistInitialState: true,
})

export function getUserId() {
  return store.getState().id
}

export function useUserId() {
  return store((state) => state.id)
}
