import { LocalStoragePersistOptions, localStoragePersist } from "src/utilities/localStorage/persist"
import { syncStoreAccrossTabs } from "src/utilities/localStorage/sync"
import { create as createStore } from "zustand"

export const createPersistentStore = <State>(
  createDefaultState: () => State,
  options: LocalStoragePersistOptions<State>,
) => {
  const store = createStore(localStoragePersist(createDefaultState, options))

  syncStoreAccrossTabs(store)

  return store
}
