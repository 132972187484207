import { isBrowser } from "src/utilities/env"
import { StateStorage } from "zustand/middleware"

/**
 * An SSR-safe zustand storage implementation.
 */
export class SsrStorage implements StateStorage {
  constructor(private readonly storage: () => Storage) {}

  getItem(name: string) {
    if (isBrowser) {
      return this.storage().getItem(name)
    }
    return null
  }

  setItem(name: string, json: string) {
    if (isBrowser) {
      this.storage().setItem(name, json)
    }
  }

  removeItem(name: string) {
    if (isBrowser) {
      this.storage().removeItem(name)
    }
  }
}
