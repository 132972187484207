import { useEventListener } from "ahooks"

export const useWindowEvent: typeof window.addEventListener = (type: any, listener: any, options: any) => {
  const forwardedOptions: EventListenerOptions = { passive: true, ...options }
  return useEventListener(type, listener, forwardedOptions)
}

export const addWindowEventForever: Window["addEventListener"] = (type: any, listener: any, options: any) => {
  const forwardedOptions: EventListenerOptions = { passive: true, ...options }
  window.addEventListener(type, listener, forwardedOptions)
}
