// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { EdgeOptions, NodeOptions } from "@sentry/nextjs"

const dsn = "https://2b3d40105da5db2cf40c8291b42feef7@o4508006966558720.ingest.de.sentry.io/4508007064535120"
const environment = process.env.NEXT_PUBLIC_STAGE || (process.env.NODE_ENV === "development" ? "local" : "unknown")
const enabled = !!process.env.SENTRY_AUTH_TOKEN

export const sentryOptions: NodeOptions | EdgeOptions = {
  dsn: dsn,
  environment: environment,
  enabled: enabled,

  // Adjust this value in production, or use tracesSampler for greater control
  // tracesSampleRate: 1,
  enableTracing: false,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  // Please use the dashboard instead of deploying a bunch of message filters.
  // https://elvent.sentry.io/settings/projects/frontend/filters/data-filters/
  ignoreErrors: [],

  // Defaults to 250. Increase this so error messages are not truncated.
  maxValueLength: 1000,
}
