import { isEqual } from "lodash"
import { addWindowEventForever } from "src/hooks/useWindowEvent"
import { isBrowser } from "src/utilities/env"
import { Mutate, StoreApi } from "zustand"

// See
// https://docs.pmnd.rs/zustand/integrations/persisting-store-data#how-can-i-rehydrate-on-storage-event?

type StoreWithPersist<A, B> = Mutate<StoreApi<A>, [["zustand/persist", B]]>

export function syncStoreAccrossTabs<A, B>(store: StoreWithPersist<A, B>) {
  if (!isBrowser) {
    return
  }

  const storageKey = store.persist.getOptions().name

  addWindowEventForever("storage", (event: StorageEvent) => {
    if (event.key !== storageKey) {
      return
    }

    const oldState = store.getState()
    const newState = JSON.parse(event.newValue || "null")?.state

    if (isEqual(oldState, newState)) {
      return
    }

    void store.persist.rehydrate()
  })
}
